const validateField = (name, value, data) => {
    const emailRegEx = /\S+@\S+\.\S+/
    const phoneRegEx = /^[0-9+]{9,9}$/
    let isInvalid = false
    let msg = ""

    const { required, type, minLength, maxLength } = data[name]

    if (required) {
        if (!value) {
            isInvalid = true
            msg = "Pole jest wymagane"
        } else {
            if (minLength && value.length < minLength) {
                msg = "Minimalna ilośc znaków to " + minLength
                isInvalid = true
            }

            if (maxLength && value.length > maxLength) {
                msg = "Maksymalna ilośc znaków to " + maxLength
                isInvalid = true
            }
        }
    } else if (value) {
        if (minLength && value.length < minLength) {
            msg = "Minimalna ilośc znaków to " + minLength
            isInvalid = true
        }

        if (maxLength && value.length > maxLength) {
            msg = "Maksymalna ilośc znaków to " + maxLength
            isInvalid = true
        }
    }

    if (type === "email" && value && !emailRegEx.test(value)) {
        msg = "To nie jest poprawny adres email"
        isInvalid = true
    }

    if (name === "phone" && value && !phoneRegEx.test(value)) {
        msg = "To nie jest poprawny numer telefonu"
        isInvalid = true
    }

    return { isInvalid, msg }
}

export default validateField
