import React, { useEffect, useState } from "react";

import Layout from "../layout";
import SEO from "../components/seo";
import HelpView from "../components/organisms/HelpView/HelpView";

const Help = ({ location }) => {
    return (
        <Layout>
            <SEO title="Pomoc" />
            <HelpView showForm={location?.state?.form ? true : false} />
        </Layout>
    );
};

export default Help;
