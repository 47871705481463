import validateField from "./validateField";

const updateFormField = (name, value, formData) => {
    const { isInvalid, msg } = validateField(name, value, formData);
    let output = { ...formData };

    output[name] = {
        ...output[name],
        value: value,
        invalid: isInvalid,
        valid: !isInvalid,
        msg: msg,
        invalidMsg: "",
    };

    if (
        output[name].type === "password" &&
        output.password &&
        output.passwordConfirmation
    ) {
        let p = output.password;
        let pc = output.passwordConfirmation;

        if (p.value && pc.value) {
            p.invalid = pc.invalid = p.value !== pc.value;
            p.valid = pc.valid = p.value === pc.value;
            pc.msg = "Hasła nie są identyczne";
            pc.invalidMsg = "";
        }
    }

    return output;
};

export default updateFormField;
